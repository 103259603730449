<template>
  <div class="apply_main">
    <div class="apply_cont">
      <div class="apply_title_box">
        <h5>认证报名</h5>
      </div>
      <div class="cont_wrapper">
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 17 }"
          @submit="handleSubmit"
        >
          <a-form-item label="姓名">
            <a-input
              v-decorator="[
                'username',
                {
                  rules: [{ required: true, message: '请输入姓名' }],
                },
              ]"
              placeholder="请输入姓名"
            />
          </a-form-item>
          <a-form-item label="手机号">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [
                    { required: true, message: '请输入手机号' },
                    { validator: phoneCheck.bind(this) },
                  ],
                },
              ]"
              placeholder="请输入手机号"
            />
          </a-form-item>
          <a-form-item class="area_box">
            <template slot="label">
              <span class="area">区域</span>
            </template>
            <a-form-item
              :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            >
              <a-select
                v-decorator="[
                  'province',
                  {
                    rules: [{ required: true, message: '请选择省' }],
                  },
                ]"
                placeholder="请选择省"
                @change="selectProvince"
                
              >
                <a-select-option
                  v-for="item in pickers"
                  :key="item.id"
                  :value="item.id"
                  >{{item.text}}</a-select-option
                >
              </a-select>
            </a-form-item>
            <span
              :style="{
                display: 'inline-block',
                width: '24px',
                textAlign: 'center',
              }"
            >
              -
            </span>
            <a-form-item
              :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            >
              <a-select
                v-decorator="[
                  'city',
                  {
                    rules: [{ required: true, message: '请选择市' }],
                  },
                ]"
                placeholder="请选择市"
                
              >
                <a-select-option
                  v-for="item in citys"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-form-item>
          <a-form-item label="学段" class="area_box">
            <a-select
              v-decorator="[
                'phase',
                {
                  rules: [{ required: true, message: '请选择学段' }],
                },
              ]"
              placeholder="请选择学段"
            >
              <a-select-option
                v-for="(item, index) in phases"
                :key="index"
                :value="item.id"
                >{{ item.text }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input
              v-decorator="[
                'email',
                {
                  rules: [{ required: false, message: '请输入邮箱' }],
                },
              ]"
              placeholder="请输入邮箱"
            />
          </a-form-item>
          <a-form-item class="btn_box">
            <a-button
              class="btn"
              type="primary"
              shape="round"
              html-type="submit"
              >报名</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  signUp,
  selectPhaseList,
  areaListByParentId,
  selectUserDetail
} from "@/apis/certificate.js";
import {mapState} from 'vuex';
export default {
  name: "apply",
  data() {
    return {
      form: this.$form.createForm(this),
      //选择器数据
      pickers: [],
      citys: [],
      phases: [],
      //回显下拉框的值
      pickerId:'',
      cityId:'',
      phaseId:''
    };
  },
  computed: {
			...mapState({
				proToken: state => state.indexStore.B_PRO_TOKEN,
			})
		},
  created() {
    // 获取用户信息，如果有加载模块页面， 没有跳转登录页
			if(!this.proToken) {
				this.$router.push({
					path: "/VclassesCenterLogin",
					query: {
						navtype: 'certificate',
					}
				})
			}
    this.getUserInfo();
    // this.getSelectPhaseList();
    // this.getArea(999999);
  },
  methods: {
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (value == undefined && value == "undefined") {
        callbackFn("请输入手机号码");
        return;
      } else if (
        value != undefined &&
        value != "undefined" &&
        !reg.test(value)
      ) {
        callbackFn("请输入正确手机号码");
        return;
      }
      callbackFn();
    },
    //获取用户已有信息
    async getUserInfo(){
      try{
        let userDetail=await selectUserDetail({});
        //处理user接口
          let msg=userDetail.data;
        if(msg.status==0){
          let obj=msg.result;
          let username=obj.userName?obj.userName:'';
          let tel=obj.phone?obj.phone:'';
          let email=obj.email?obj.email:'';
          
          this.$nextTick(()=>{
            this.form.setFieldsValue({'username':username,'phone':tel,'email':email});
          })
          // obj.phaseId=2;  
          // obj.provinceId=9;
          // obj.cityId=109;

           let phaseList=await this.getSelectPhaseList();
            this.phases = phaseList.map((item) => {
                if(obj.phaseId!=null&&obj.phaseId!=undefined&&obj.phaseId!=''&&obj.phaseId==item.phaseId){
                  this.phaseId=item.phaseId;
                }
                return { id: item.phaseId, text: item.phaseName };
            });
          //回显学段
          if( this.phaseId!=null&& this.phaseId!=''){
            this.$nextTick(()=>{
              this.form.setFieldsValue({'phase':this.phaseId})
            })
          }

          let provinList=await this.getArea('999999');
          this.pickers = provinList.map((item) => {
                if((obj.provinceId!=null&&obj.provinceId!=undefined&&obj.provinceId!=''&&obj.provinceId==item.areaId)||(obj.provinceName!=null&&obj.provinceName!=undefined&&obj.provinceName!=''&&obj.provinceName==item.areaName)){
                  this.pickerId=item.areaId;
                }
                return { id: item.areaId, text: item.areaName };
              }); 
          //回显省
          if(this.pickerId!=''&&this.pickerId!=null){
              this.$nextTick(()=>{
                  this.form.setFieldsValue({'province':this.pickerId})
                })
          }
          //回显市、县  
          if((obj.cityId!=null&&obj.cityId!=undefined&&obj.cityId!='')||(obj.cityName!=null&&obj.cityName!=undefined&&obj.cityName!='')){
            let cityList=await this.getArea(this.pickerId);
            if(cityList&&cityList.length>0){
              this.cityId=obj.cityId;
              this.citys = cityList.map((item) => {
                if((obj.cityId!=null&&obj.cityId!=undefined&&obj.cityId!=''&&obj.cityId==item.areaId)||(obj.cityName!=null&&obj.cityName!=undefined&&obj.cityName!=''&&obj.cityName==item.areaName)){
                  this.cityId=item.areaId;
                }
                return { id: item.areaId, text: item.areaName };
              });
              if(this.cityId!=null&&this.cityId!=''){
                this.$nextTick(()=>{
                this.form.setFieldsValue({'city':this.cityId})
              })
              }
            }
          }
         
        }
      }catch{
        console.log('err');
      }
    },
    async selectProvince(e) {
      this.form.resetFields(["city"]);
      let cityList=await this.getArea(e);
        if(cityList&&cityList.length>0){
            this.citys = cityList.map((item) => {
                return { id: item.areaId, text: item.areaName };
              });
        }
    },
    //获取地区
    getArea(id) {
      return new Promise((resolve,reject)=>{
      areaListByParentId({ areaId: id }).then((res) => {
        let msg = res.data;
        if (msg.status == 0) {
          resolve(msg.result);
        }else{
          reject([]);
        }
      });
      })
    },
    //获取学段数据
    getSelectPhaseList() {
      return new Promise((resolve,reject)=>{
      selectPhaseList({}).then((res) => {
        let msg = res.data;
        if (msg.status == 0) {
          resolve(msg.result);
          
        }else{
          reject([]);
        }
      });
      })
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let that=this;
          // console.log("Received values of form: ", values);
          let params = {
            countryId: 999999,
            provinceId: values.province,
            cityId: values.city,
            phaseId: values.phase,
            signUpName: values.username,
            signUpPhone: values.phone,
            signUpEmail: values.email,
            giveLessonsId: this.$route.query.id,
          };
          signUp(params).then((res) => {
            let msg = res.data;
            if (msg.status == 0) {
              that.$message.success('成功');
              setTimeout(() => {
                that.$router.back(-1);
              }, 1500);
            } else {
              that.$message.error(msg.msg);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin title_icon($url) {
  content: "";
  width: 107px;
  height: 7px;
  background: url($url) top center no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: calc(50% - 3px);
}
.apply_main {
  background: #f8f9fa;
  padding-top: 23px;
  .apply_cont {
    width: 1200px;
    height: 843px;
    background: #ffffff;
    margin: 0 auto;
    .apply_title_box {
      padding-top: 46px;
      width: 100%;
      h5 {
        margin: 0 auto;
        width: 96px;
        height: 33px;
        font-size: 24px;
        font-weight: 500;
        color: #5d5aed;
        line-height: 33px;
        position: relative;
        &::before {
          @include title_icon("../../assets/imgs/cer/apply_l_icon.png");
          left: -151px;
        }
        &::after {
          @include title_icon("../../assets/imgs/cer/apply_r_icon.png");
          right: -151px;
        }
      }
    }
    .cont_wrapper {
      width: 500px;
      margin: 59px auto 0 auto;
      ::v-deep .ant-input {
        padding: 11px;
        height: 50px;
      }
      ::v-deep .ant-select-selection--single {
        height: 50px;
      }
      ::v-deep .ant-select-selection__rendered {
        line-height: 50px;
      }
      ::v-deep .ant-form-item-required {
        font-size: 16px;
        font-weight: 400;
        color: #606978;
      }
      .area_box {
        ::v-deep .ant-row {
          margin-bottom: 0;
        }
        .area {
          position: relative;
          font-size: 16px;
          font-weight: 400;
          color: #606978;
          &::before {
            display: inline-block;
            margin-right: 4px;
            color: #f5222d;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
          }
        }
      }
      .btn_box {
        margin-top: 36px;
        ::v-deep .ant-col {
          width: 100% !important;
          text-align: center;
        }
        ::v-deep .ant-btn {
          height: 58px;
          background: #5d5aed;
          box-shadow: 0px 12px 24px 0px rgba(93, 90, 237, 0.3);
          border-radius: 999px;
          padding: 0 98px;
          font-size: 22px;
        }
      }
    }
  }
}
</style>