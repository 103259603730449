var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"apply_main"},[_c('div',{staticClass:"apply_cont"},[_vm._m(0),_c('div',{staticClass:"cont_wrapper"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {
                rules: [{ required: true, message: '请输入姓名' }],
              } ]),expression:"[\n              'username',\n              {\n                rules: [{ required: true, message: '请输入姓名' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  { required: true, message: '请输入手机号' },
                  { validator: _vm.phoneCheck.bind(this) } ],
              } ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  { required: true, message: '请输入手机号' },\n                  { validator: phoneCheck.bind(this) },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入手机号"}})],1),_c('a-form-item',{staticClass:"area_box"},[_c('template',{slot:"label"},[_c('span',{staticClass:"area"},[_vm._v("区域")])]),_c('a-form-item',{style:({ display: 'inline-block', width: 'calc(50% - 12px)' })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'province',
                {
                  rules: [{ required: true, message: '请选择省' }],
                } ]),expression:"[\n                'province',\n                {\n                  rules: [{ required: true, message: '请选择省' }],\n                },\n              ]"}],attrs:{"placeholder":"请选择省"},on:{"change":_vm.selectProvince}},_vm._l((_vm.pickers),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.text))])}),1)],1),_c('span',{style:({
              display: 'inline-block',
              width: '24px',
              textAlign: 'center',
            })},[_vm._v(" - ")]),_c('a-form-item',{style:({ display: 'inline-block', width: 'calc(50% - 12px)' })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'city',
                {
                  rules: [{ required: true, message: '请选择市' }],
                } ]),expression:"[\n                'city',\n                {\n                  rules: [{ required: true, message: '请选择市' }],\n                },\n              ]"}],attrs:{"placeholder":"请选择市"}},_vm._l((_vm.citys),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.text))])}),1)],1)],2),_c('a-form-item',{staticClass:"area_box",attrs:{"label":"学段"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phase',
              {
                rules: [{ required: true, message: '请选择学段' }],
              } ]),expression:"[\n              'phase',\n              {\n                rules: [{ required: true, message: '请选择学段' }],\n              },\n            ]"}],attrs:{"placeholder":"请选择学段"}},_vm._l((_vm.phases),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [{ required: false, message: '请输入邮箱' }],
              } ]),expression:"[\n              'email',\n              {\n                rules: [{ required: false, message: '请输入邮箱' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入邮箱"}})],1),_c('a-form-item',{staticClass:"btn_box"},[_c('a-button',{staticClass:"btn",attrs:{"type":"primary","shape":"round","html-type":"submit"}},[_vm._v("报名")])],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"apply_title_box"},[_c('h5',[_vm._v("认证报名")])])}]

export { render, staticRenderFns }